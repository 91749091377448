import md5 from 'js-md5';//引入md5
import Qs from 'qs'//post data数据格式转换
import router from '@/router'
import request from './request'
import { Toast } from 'mint-ui';

import exoprtStorage from '../utils/modules/storage'
import exoprtArrayFun from '../utils/modules/ArrayFun'


//固定位数随机字符串,默认32位
export function randomString(len) {
  　　len = len || 32;
    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  　　var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    
  　　var maxPos = $chars.length;
  　　var pwd = '';
  　　for (var i = 0; i < len; i++) {
  　　　　pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  　　}
  　　return pwd;
  }
 //对象键值排序
 export function objKeySort(obj) {
  var newkey = Object.keys(obj).sort()
  var newObj = {}
  for (var i = 0; i < newkey.length; i++) {
      newObj[newkey[i]] = obj[newkey[i]];
  }
  return newObj;
}
//签名使用
export function makeSign(data){
  data=objKeySort(data)
  if(data.hasOwnProperty('sign')){
	  delete data['sign']
  }
  data=Qs.stringify(data)
  var appkey='c9352a66ea81b9c93501e64eea5c5964'
  data=data+'&key='+appkey
  return md5(data).toLowerCase()
}

export const requests = function(method,e,that) {
	if (!method) {
		console.log('缺少设置请求类型')
		return
	}
	if (!e.hasOwnProperty('url')) {
		console.log('缺少url')
		return
	}
	let obj = {}
	
	
	if (method == 'post') {
		obj = {
			url: e.url,
			method: 'post',
			data: e.data
		}
	} else if (method == 'get') {
		obj = {
			url: e.url,
			method: 'get',
			params: e.data
		}
	}
	if(e.headers){
		obj.headers=e.headers
	}
	
	return request(obj)
}
export const getQueryVariable = function(name)  { // 获取url的值
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    var r = window.location.hash.substr(1).match(reg);//search,查询？后面的参数，并匹配正则
    if(r!=null)return  unescape(r[2]); return null;

 }
 /**
  * 跳转
  * @param {*} e={url,data,hreftype}
  * @param {*} dotype 跳转传参类型
  */
 export const openPage = function(e,dotype='get') {
	 if (!e.hasOwnProperty('url')) {
		 console.log('缺少跳转url')
		 return
	 }
 
	 var urlobj = {}
	 if(!e.hasOwnProperty('data')) {
		 e.data={}//类型
	 }
	 if(dotype=='post'){//url不显示参数,类似post
		 urlobj={
			 name: e.url.replace(/\//g,''), //约定name为url去除'/'所拼接的
			 params: e.data  //跳转后获取从params获取参数数据
		 }
	 }else{ //url显示参数,类似get
		 urlobj={
			 path: e.url,
			 query: e.data //跳转后获取从query获取参数数据
		 }
	 }
 
	 switch (e.hreftype) {
		 case 'redirectTo':
			 return router.replace(urlobj)
			 break;
		 case 'navigateBack':
			 if (parseInt(e.url) >= 0) {
				 console.log('请设置为负数,即返回的层数');
				 return
			 }
			 return router.go(e.url)
			 break;
	 default: //navigateTo
			 return router.push(urlobj)
	 }
 }
//去除字符串两端空格
export const trim=function(str){
	return str.replace(/(^\s*)|(\s*$)/g, "");
}

//删除操作和提示
export const doDel=function(e){
	//e={url，data,successFun}
	if(!e.url){
		console.log('缺少指定url')
		return
	}
	MessageBox.confirm('确认删除?', '提示', {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then(() => {
		requests('post',{url:e.url,data:e.data}).then(function(res){
			e.successFun(res)
			message.success('删除成功')
		})
	}).catch(() =>{});

}

//vue渲染经过转义的html字符串方法 html解码
export const htmlDecode=function(str) {
	let e = document.createElement('div');
	e.innerHTML = str;
	return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

export const showToast = function(msg="操作成功", iconClass="", duration=3000){
	Toast({
		message: msg,
		iconClass: iconClass,
		duration: duration
	});
}
//  判断微信 ios 安卓 web端的配置
export const phoneType = function(e) {
    var ua = window.navigator.userAgent;
    if(ua.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
        return 'weixin';
    } else {
        if (ua.indexOf("Android") > -1 || ua.indexOf("Linux") > -1) {
            return 'Android';
        } else if (ua.indexOf("iPhone") > -1 || ua.indexOf("iOS") > -1) {
            return 'IOS';
        } else {
            return 'web';
        }
    }
}
export const myStorage=exoprtStorage; //本地存储与本地会话存储操作
export const ArrayFun=exoprtArrayFun; //数组操作

 
