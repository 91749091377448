import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import zhLocal from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import enLocal from 'element-ui/lib/locale/lang/en' // lang i18n
import App from './App.vue'
import router from './router'
import store from './store'
import 'swiper/css/swiper.css';
import 'mint-ui/lib/style.css'
import Mint from 'mint-ui'
import wx from 'weixin-js-sdk'
import VueI18n from 'vue-i18n'
import { getLanguage } from '@/utils/auth'
import "./css/index.scss"
import 'animate.css'
import '@/permission'
import VueCoreVideoPlayer from "vue-core-video-player"
import zh_cn_play from "@/lang/zh-cn"
Vue.use(VueCoreVideoPlayer);
var util = require("@/utils/util");
Vue.use(VueI18n);
// import  VConsole  from  'vconsole';
// let vConsole = new VConsole();
Vue.use(Mint);
let lang = getLanguage() ? getLanguage() : 'cn'
if (lang == 'cn') {
  Vue.use(ElementUI, { locale: zhLocal })
}
if (lang == 'en') {
  Vue.use(ElementUI, { locale: enLocal })
}

Vue.config.productionTip = false;
Vue.prototype.setDataArr = function (obj) {
  Object.assign(this.$data, obj);
}
// 图片放大组件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
Viewer.setDefaults({
  zIndexInline: 9999
})

//路由跳转后，页面回到顶部
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  setTimeout(() => {
    // util.requests("post", {
    //   url: "os/commonGetList",
    //   data : { table : 'sss' }
    //   }).then((res) => {

    //   })
    // 此处粘贴百度统计复制过来的代码
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?733ed6b02d7c533ae87506c3ba1d1be2";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }, 0);
})

const i18n = new VueI18n({
  locale: getLanguage() ? getLanguage() : 'cn', // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'cn': require('./assets/languages/cn.json'),  // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
    'en': require('./assets/languages/en.json'),
    'kr': require('./assets/languages/kr.json'),
    'mn': require('./assets/languages/mn.json'),
    'ru': require('./assets/languages/ru.json'),
  },
  silentTranslationWarn: true, // 去除国际化警告

});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
