import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this,location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/special/detail',
    name: '/special/detail',
    component: () => import('@/views/special/detail.vue'),
  },
  {
    path: '/service/question',
    name: '/service/question',
    component: () => import('@/views/service/question.vue'),
  },
  {
    path: '/special/case',
    name: '/special/case',
    component: () => import('@/views/special/case.vue'),
  },
  // {
  //   path: '/survey/detail',
  //   name: 'SurveyDetail',
  //   component: SurveyDetail
  // },
  // {
  //   path: '/order',
  //   name: 'Order',
  //   component: Order
  // },
  // {
  //   path: '/scenic',
  //   name: 'Scenic',
  //   component: Scenic
  // },

  // {
  //   path: '/service',
  //   name: 'Service',
  //   component: Service
  // },
  // {
  //   path: '/person',
  //   name: 'Person',
  //   component: Person
  // },
  // {
  //   path: '/detail',
  //   name: 'Detail',
  //   component: Detail
  // },
  // {
  //   path: '/information',
  //   name: 'Information',
  //   component: Information
  // }
]

const router = new VueRouter({
  routes
})

export default router
