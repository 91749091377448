const getters = {
    menuList : state => state.header.menuList,
    logo : state => state.header.logo,
    name : state => state.header.name,
    langArr : state => state.header.langArr,
    themeColor : state => state.header.themeColor,
    tabList : state => state.header.tabList,
    nowLocation : state => state.header.nowLocation,
    secondMenu  : state => state.header.secondMenu,
    config : state => state.header.config,
    bennrtList : state => state.header.bennrtList
}
export default getters