<template>
  <section class="header-tab flex ac jb">
    <div class="left flex ac jc">
      <div 
      v-if="config.system_info"
        class="logo-img"
        :style="{
          background: `url(${config.system_info.logo.value[0]})`,
        }"
      ></div>
      <!-- <img v-if="logo" src="@/assets/body/header_logo.png" alt="" /> -->
    </div>
    <div class="main flex ac">
      <section
        :key="index"
        @click="getRoute(item, item.page)"
        v-for="(item, index) in menuList"
        :class="['tabs flex ac jc']"
      >
        {{ $t(item.name) }}
        <div
          :class="[
            'bottom-radius flex ac jc',
            activeId == item.id ? 'active' : '',
          ]"
        ></div>
        <section v-if="item.children.length > 0" class="header-pop">
          <section
            :key="childIndex"
            :class="['flex ac jc pop-name', secondMenu == childItem.name ? 'active' : '']"
            @click="
              getModel(
                childItem,
                childItem.page,
                item.name,
                childItem.name,
                item.banner_list
              )
            "
            v-for="(childItem, childIndex) in item.children"
          >
            <div class="only">{{ $t(childItem.name) }}</div>
          </section>
        </section>
      </section>
    </div>
    <div class="right flex ac">
      <!-- <el-dropdown @command="changeLang" v-if="langArr && langArr.length>0">
        <span class="el-dropdown-link outline">
          {{ langArr[0].name }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :key="index"
            v-for="(item, index) in langArr"
            :command="item.code"
            >{{ $t(item.name) }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
  </section>
</template>
<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex";
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { getLanguage, setLanguage } from "@/utils/auth";
import { Indicator } from "mint-ui";
export default {
  name: "home",
  components: {
    HeaderTab,
    footerTab,
  },
  computed: {
    ...mapGetters(["menuList", "logo", "name","config","secondMenu"]),
  },
  props: {
    getPoint: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      lang: "",
      id: "",
      info: "",
      activeId: "",
      langArr: [
      {
        name: '简体中文',
        code: 'cn'
      },
    ]
    };
  },
  async mounted() {
    Indicator.open(this.$t("tip.loading"));
    // 获取语言列表
    // await this.getLangConfig();
    this.lang = getLanguage();
    // 获取官网菜单+对应banner列表
    await this.getMenuList();
    if (!this.$store.getters.config) {
      await this.getSettingList();
    }

    Indicator.close();
  },
  methods: {
    getLangConfig() {
      const ts = this;
      return new Promise((resolve) => {
        util
          .requests("get", {
            url: "os/getLangConfig",
          })
          .then((res) => {
            if (res.code && res.data.length > 0) {
              const langArr = res.data.map((item, index) => {
                const newItem = { ...item };
                newItem.val = item.code;
                return newItem;
              });
              if (langArr.length > 0) {
                ts.$store.dispatch("setLangArr", langArr);
                // console.log("!getLanguage()", !getLanguage());
                if (!getLanguage()) {
                  ts.lang = langArr[0].val;
                  setLanguage(ts.lang);
                  console.log("set--ts.lang", ts.lang);
                }
              }
            }
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    getMenuList() {
      const ts = this;
      return new Promise((resolve) => {
        util
          .requests("post", {
            url: "os/getMenuList",
          })
          .then((res) => {
            if (res.code) {
              ts.$store.dispatch("setMenuList", res.data.list);
              // if ( ts.$route.query.parent_id ) {
              let tabList = [];
              let secondMenu = "";
              let firstMenu = "";
              let bennrtList = [];
              res.data.list.forEach((item, index) => {
                if (item.id == ts.$route.query.parent_id) {
                  tabList = item.children;
                  firstMenu = item.name;
                  bennrtList = item.banner_list;
                }
                if (!ts.$route.query.parent_id && index == 0) { // 首页
                  bennrtList = item.banner_list;
                }
              });
              if (tabList.length > 0) {
                tabList.forEach((item, index) => {
                  if (item.id == ts.$route.query.id) {
                    secondMenu = item.name;
                  }
                });
              }
              const nowLocation = "首页" + " > " + firstMenu + " > " + secondMenu;
              ts.$store.dispatch("setNowLocation", nowLocation);
              ts.$store.dispatch("setTabList", tabList);
              ts.$store.dispatch("setSecondMenu", secondMenu);
              ts.$store.dispatch("setBennrtList", bennrtList);
              // }
            }
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },

    getSettingList() {
      const ts = this;
      return new Promise((resolve) => {
        util
          .requests("post", {
            url: "os/getSettingList",
          })
          .then((res) => {
            if (res.code) {
              ts.info = res.data;
              ts.$store.dispatch("setConfig", res.data);
              if (res.data.system_info.logo.value.length > 0) {
                ts.$store.dispatch(
                  "setLogo",
                  res.data.system_info.logo.value[0]
                );
              }
              if (res.data.system_info.scenic_name.value) {
                ts.$store.dispatch(
                  "setName",
                  res.data.system_info.scenic_name.value
                );
                document.title = res.data.system_info.scenic_name.value;
              }
              if (res.data.style.color) {
                ts.$store.dispatch("setThemeColor", res.data.style);
              }
            }
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },

    changeLang(command) {
      const ts = this;
      return new Promise((resolve) => {
        util
          .requests("get", {
            url: "os/getLangConfig",
          })
          .then((res) => {
            console.log(res);
            if (res.code && res.data.length > 0) {
              const isContainer = false;
              const langArr = res.data.map((item, index) => {
                // 如果包含这个语种才更新语种
                if (item.code == command) {
                  setLanguage(command);
                }
              });
            }
            if (ts.$route.path == "/") {
              window.location.reload();
            } else {
              util.openPage({ url: "/" });
              window.location.reload();
            }
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    getRoute(e, url) {
      // 如果是外链
      if (e.jump_type == 2) {
        if (e.url) {
          window.open(e.url, "_blank");
        }
        return;
      }
      let bennrtList = e.banner_list;
      if(e.name === '首页') {
        this.$store.dispatch("setSecondMenu", null);
        this.$store.dispatch("setBennrtList", bennrtList);
        util.openPage({ url });
      }
    },
    getModel(e, url, firstMenu, secondMenu, bennrt_list) {
      // 如果是外链
      if (e.jump_type == 2) {
        window.open(e.url, "_blank");
        return;
      }
      let nowLocation
      // this.activeId = e.id
      if (firstMenu == '旅游服务') { // 旅游服务
        nowLocation = "首页" + " > " + firstMenu;
      } else {
        nowLocation = "首页" + " > " + firstMenu + " > " + secondMenu;
      }
      this.$store.dispatch("setNowLocation", nowLocation);
      this.$store.dispatch("setSecondMenu", secondMenu);
      this.$store.dispatch("setBennrtList", bennrt_list);
      util.openPage({ url, data: { id: e.id, parent_id: e.parent_id } });
      
    },
  },
};
</script>
<style lang="scss" scoped>
.outline {
  outline: 1px solid #fff;
  color: #fff;
  padding:9px 16px;
  border-radius: 4px;
}
.logo-img {
  width: 180px;
  height: 60px;
  margin-right: 15px;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}
// .header-pop { 
//   .active {
//     color: var(--bg-color);
//   }
// }
</style>