<template>
  <div class="container-home">
    <el-carousel v-if="bennrtList.length !== 0" height="10.80rem" :autoplay="true">
      <el-carousel-item v-for="(item, index) in bennrtList" :key="index">
        <div
          v-if="item.material_type === 1"
          @click="getRoute(item)"
          :style="{ background: `url(${item.domain_image}) no-repeat` }"
          class="images"
        ></div>
        <div 
        v-if="item.material_type === 2" 
        style="text-align: center;">
          <video
            ref="video"
            id="video"
            :src="item.video_url"
            :autoplay="true"
            loop muted="muted"
            webkit-playsinline="true"
            style="height:100%;"
          ></video>
        </div>
      </el-carousel-item>
      <div class="carousel_bottom" v-if="noticList[0]">
        <div class="carousel_bar flex ac jb">
          <div class="scenicSpot flex jc ac only" v-if="noticList[0]">
            <span class="only">
              {{ $t("home.notice") }}
            </span>
          </div>
          <div
            class="font_20 blod only"
            v-if="noticList[0]"
            style="width: 400px"
          >
            {{ $t(noticList[0].synopsis) }}
          </div>
          <div class="font_20" v-if="noticList[0]">
            {{ $t(noticList[0].create_time_str) }}
          </div>
          <div
            class="font_20"
            @click="goPage('/dynamic/notice')"
            style="cursor: pointer"
          >
            {{ $t("home.more_") }}
          </div>
          <div class="font_32">{{ $t(config.home.scenic_tel.value) }}</div>
        </div>
      </div>
    </el-carousel>
    <!-- <el-dialog :visible.sync="dialogVisible" width="30%">
      <vue-core-video-player :src="videoSrc" ></vue-core-video-player>
    </el-dialog> -->
    <div class="container flex jc ac dc">
      <div class="flex jc ac" data-aos="fade-up">
        <img src="@/assets/body/slide.png" alt="" class="slide" />
        <div class="font_size">{{ $t("home.luling") }}</div>
        <img src="@/assets/body/slide.png" alt="" class="slide rotate_180" />
      </div>
    </div>
    <div class="sections_1">
      <div class="left">
        <div class="boat">
          <div class="nav" v-if="config">
            <div
              class="qrcode"
              :style="{
                background: `url(${config.home.qrcode_image.value[0]})`,
              }"
            ></div>
            <div class="font_24" style="word-break: break-all;white-space: unset;">
              {{ config.home.qrcode_name.value }}
            </div>
            <div class="font_16 mt_24" style="text-align: left;">
              <img
                src="@/assets/body/phone_logo.png"
                alt="电话"
                class="icont_style"
              />
              {{ $t(config.home.scenic_tel.description) }}
            </div>
            <div class="font_24 bold"
            style="word-break: break-all;white-space: unset;text-align: left;text-indent: 18px;">
              {{ $t(config.home.scenic_tel.value) }}
            </div>
            <div class="font_16 mt_24" style="text-align: left;">
              <img
                src="@/assets/body/clock_logo.png"
                alt="时钟"
                class="icont_style"
              />
              {{ $t(config.home.service_time.description) }}
            </div>
            <div class="font_24 bold"
            style="word-break: break-all;white-space: unset;text-align: left;text-indent: 18px;">
              {{ $t(config.home.service_time.value) }}
            </div>
          </div>
        </div>
      </div>

      <div
        data-aos="slide-up"
        class="hover_bar flex dc ac only"
        v-if="config.business_hours_info"
      > 
        <img src="@/assets/body/black_logo.png" height="110px"/>
        <div class="hover_title">{{ $t("home.luling") }}</div>
        <div class="font_24 bold afs"
        style="word-break: break-all;white-space: break-spaces;">
          {{ $t(config.business_hours_info.title1.value) }}
        </div>
        <div class="font_16 mt_24 afs"
        style="word-break: break-all;white-space: break-spaces;">
          {{ $t(config.business_hours_info.content1.value) }}
        </div>
        <div class="font_24 bold afs"
        style="word-break: break-all;white-space: break-spaces;">
          {{ $t(config.business_hours_info.title2.value) }}
        </div>
        <div class="font_16 mt_24 afs"
        style="word-break: break-all;white-space: break-spaces;">
          {{ $t(config.business_hours_info.content2.value) }}
        </div>
        <div class="font_16 mt_24 afs"
        style="word-break: break-all;white-space: break-spaces;color:#999">
          {{ $t(config.business_hours_info.remark.value) }}
        </div>
        <div class="flex jc ac gap">
          <div class="flex jc dc ac" @click="goToInfo(i,'especially')"
          v-for="(i, index) in tranServers" :key="index">
            <img :src="require('@/assets/icon/'+i.code+'.png')" class="icont-logo" />
            <div class="font_20">{{ $t(i.name) }}</div>
          </div>
        </div>
      </div>
      <div class="right" data-aos="zoom-in-down"></div>
    </div>
    <!-- 精彩活动 -->
    <div 
    :style="{ backgroundImage: 'url(' + config.activity_info.image.value[0] + ')' }"
    class="sections_ sections_2" 
    v-if="config.activity_info && getMenuAuth('/dynamic/activity')">
      <div class="flex jc dc ac subtitle_">
        <div class="flex jc ac" style="margin-bottom: 32px;">
          <img src="@/assets/body/slide.png" alt="" class="slide" />
          <div class="font_size">{{ $t("home.activity") }}</div>
          <img src="@/assets/body/slide.png" alt="" class="slide rotate_180" />
        </div>
        <div class="content" v-html="config.activity_info.content.value"></div>
      </div>
      <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in activityList"
            :key="index"
            @click="goToInfo(item,'activity')"
          >
            <div class="content-box-act">
              <div class="act_title only">{{item.name}}</div>
              <div class="act_label only">{{item.label}}</div>
              <div class="act_dec double" v-html="item.synopsis"></div>
            </div>
            <img :src="item.domain_image" alt="" />
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      <div
        class="more_tips flex jc ac font_18"
        @click="goPage('/dynamic/activity')"
      >
        {{ $t("home.more") }}
      </div>
    </div>
    <!-- 特色服务 -->
    <div class="sections_ sections_3" 
    v-if="config.especially_info && getMenuAuth('/special/index')">
      <div class="flex jc dc ac subtitle_">
        <div class="flex jc ac" style="margin-bottom: 32px;">
          <img src="@/assets/body/slide.png" alt="" class="slide" />
          <div class="font_size">{{ $t("home.specialService") }}</div>
          <img src="@/assets/body/slide.png" alt="" class="slide rotate_180" />
        </div>
        <div class="content" v-html="config.especially_info.content.value"></div>
      </div>
      <div class="card_content flex">
        <div
          v-for="i in tranServers"
          :key="i.name"
          @mouseenter="hover(i)"
          @mouseleave="leave(i)"
          @click="goToInfo(i,'especially')"
          class="strip flex ac jc dc"
          style="background-size: 100% 100% !important;"
          data-aos="fade-up"
          :data-aos-delay="`${100 + i * 50}`"
        >
          <div v-if="!i.ishover">
            <div class="font_40" style="text-align:center;">{{ $t(i.name) }}</div>
            <div class="lineclip"></div>
            <!-- <div
              class="strip_logo"
              :style="{ background: `url(${i.domain_index_image})` }"
            ></div> -->
            <img :src="require('@/assets/icon/'+i.code+'old.png')" class="strip_logo" />
            <div
              class="thumbnail"
              :style="{ background: `url(${i.domain_index_image})` }"
            ></div>
          </div>
          <div v-if="i.ishover">
            <div class="font_40" style="text-align:center;">{{ $t(i.name) }}</div>
            <div class="lineclip"></div>
            <!-- <div
              class="strip_logo"
              :style="{ background: `url(${i.domain_index_image})` }"
            ></div> -->
            <img :src="require('@/assets/icon/'+i.code+'new.png')" class="strip_logo" />
            <div class="strip_content" v-html="i.synopsis"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 标志景点 -->
    <div class="sections_ sections_4" v-if="config.sign_scenic_info && getMenuAuth('/surveys/scenic')">
      <div class="flex jc dc ac subtitle_">
        <div class="flex jc ac" style="margin-bottom: 32px;">
          <img src="@/assets/body/slide.png" alt="" class="slide" />
          <div class="font_size">{{$t("home.iconicAttractions")}}</div>
          <img src="@/assets/body/slide.png" alt="" class="slide rotate_180" />
        </div>
        <div class="content" v-html="config.sign_scenic_info.content.value"></div>
      </div>
      <div class="waterfall flex jc ac" v-if="scenicList[0]">
        <div @click="goToInfo(scenicList[0],'sign_scenic')"
        class="waterfall_box" data-aos="fade-up" data-aos-delay="100">
          <div
            class="waterfall_img"
            :style="{ background: `url(${scenicList[0].domain_image})` }"
          >
            <div class="waterfall_title ps_lt font_20 flex ac jc">
              <span class="only">
                {{ $t(scenicList[0].name) }}
              </span>
              <!-- 梦 回 庐 陵 · 儒 林 里 -->
            </div>
          </div>
        </div>
        <div @click="goToInfo(scenicList[1],'sign_scenic')"
        class="waterfall_box" data-aos="fade-up" data-aos-delay="200">
          <div
            class="waterfall_img ps_ct_img"
            :style="{ background: `url(${scenicList[1].domain_image})` }"
          >
            <div class="waterfall_title ps_ct font_20 flex jc ac">
              <span class="only">
                {{ $t(scenicList[1].name) }}
              </span>
            </div>
          </div>
        </div>
        <div @click="goToInfo(scenicList[2],'sign_scenic')"
        class="waterfall_box" data-aos="fade-up" data-aos-delay="300">
          <div
            class="waterfall_img ps_ct_img"
            :style="{ background: `url(${scenicList[1].domain_image})` }"
          >
            <div class="waterfall_title ps_rt font_20 flex jc ac">
              <span class="only">
                {{ $t(scenicList[2].name) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="more_tips flex jc ac font_18"
        @click="goPage('/surveys/scenic')"
      >
        {{ $t("home.more") }}
      </div>
    </div>
    <div class="right-box">
      <div
        v-if="getMenuAuth('/aboutUs/messageBoard')"
        @click="goPage('/aboutUs/messageBoard')"
        class="message-box flex dc ac jc"
      >
        <div class="message-icon"></div>
        <div class="message-text tc">{{ $t("home.message") }}</div>
      </div>
      <div v-if="showTopButton" @click="backTop" class="return-top flex dc ac jc">
        <div class="top-icon"></div>
        <div class="top-text tc">{{ $t("home.top") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { mapGetters } from "vuex";
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Indicator } from "mint-ui";
import Swiper from "swiper";
import carouselItem from "@/components/carousel/src/item.vue";
export default {
  name: "home",
  components: {
    HeaderTab,
    footerTab,
    carouselItem,
  },
  data() {
    return {
      isHover: false,
      videoSrc: "",
      dialogVisible: false,
      bannerImg: [],
      infoSwiper: null,
      articleIdx: 0,
      show: "",
      info: [],
      activity: [],
      route: [],
      news: [],
      choice_zixun: {},
      banner_info: "",
      LangeList: [],
      infoList: [],
      commonList: [],
      noticList: [],
      activityList: [],
      scenicList: [],
      // 特色服务
      tranServers: [],
      showTopButton: false,
      bannerSwiper: "",
      bannerIndex: "",
      videolist:{
        autoplay:true, // 自动播放
        controls:'controls', //操作
        controlslist:'nodownload  noplaybackrate' 
        /*
        1、nodownload: 不要下载按钮
        2、nofullscreen: 不要全屏按钮
        3、noremoteplayback: 不要远程回放
        4、disablePictureInPicture 不要画中画按钮
        5、noplaybackrate 不要播放速度按钮
        */
      }, //video配置
    };
  },
  computed: {
    ...mapGetters(["config", "bennrtList", "menuList"]),
  },
  watch: {
    activityList() {
      const ts = this;
      ts.$nextTick(() => {
        ts.initSwiper();
      });
    },
    menuList(val) {
      this.show = val[0]?.id;
    },
    bennrtList(val) {
      console.log(val,'val');
    }
  },
  async mounted() {
    AOS.init({
      easing: "ease-in-sine",
    });
    // this.show = this.$store.getters.menuList[0]?.id;
    Indicator.open(this.$t("tip.loading"));
    await this.commonGetList("os_scenic");
    await this.commonGetList("os_activity");
    // // 获取官网菜单+对应banner列表
    // await this.getMenuList();
    // // let containerInfo = this.isContainer("/dynamic/info");
    // // let containerNotice = this.isContainer("/dynamic/notice");
    // console.log("containerInfo", containerInfo);
    // console.log("containerNotice", containerNotice);
    // // if (!containerInfo && containerNotice) {
    // //   this.articleIdx = 1;
    // // }
    // // 新闻资讯列表
    // await this.commonGetList("os_information");
    await this.commonGetList("os_notice");
    await this.commonGetList("os_especially");
    this.initSwiper();
    Indicator.close();
    window.addEventListener("scroll", this.scrollToTop);
    // // this.showActivity = this.isContainer("/dynamic/activity");
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    //  获取菜单权限
    getMenuAuth(val) {
      const _arr = []
      this.$store.getters.menuList.forEach((item, index) => {
        if (item.children.length > 0) {
          if(item.children.find(item => item.page === val)) {
            _arr.push(true)
          } else {
            _arr.push(false)
          }
        }
      })
      return _arr.find(item => item === true)
    },
    hover(val) {
      val['ishover'] = true;
      this.$forceUpdate() // 强制刷新
    },
    leave(val) {
      val['ishover'] = false;
      this.$forceUpdate()
    },
    // 富文本处理
    showHtml(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000");
    },
    getRoute(e) {
      if (e.material_type == "1") {
        if (e.jump_type == 2) { // 如果是外链
          window.open(e.url, "_blank");
          return;
        } else if (e.jump_type == 1) { // 如果是内部跳转
          let resultItem = "";
          let firstMenu = "";
          let secondMenu = "";
          this.$store.getters.menuList.forEach((item, index) => {
            if (item.children.length > 0) {
              item.children.forEach((childrenItem, childrenIndex) => {
                if (childrenItem.id == e.menu_id) {
                  resultItem = childrenItem;
                  firstMenu = item.name;
                  secondMenu = childrenItem.name;
                }
              });
            }
          });
          const nowLocation = "首页" + " > " + firstMenu + " > " + secondMenu;
          this.$store.dispatch("setNowLocation", nowLocation);
          this.$store.dispatch("setSecondMenu", secondMenu);
          util.openPage({
            url: resultItem.page,
            data: { id: resultItem.id, parent_id: resultItem.parent_id },
          });
        }
      } else {
        this.videoSrc = e.video_url
        this.dialogVisible = true;
      }
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.showTopButton = true;
      } else {
        that.showTopButton = false;
      }
    },
    goPageDetail(htmlData) {
      // 0 跳新闻资源 1景区公告
      let e = "";
      let firstMenu = "";
      let secondMenu = "";
      this.$store.getters.menuList.forEach((item, index) => {
        if (item.children.length > 0) {
          item.children.forEach((childrenItem, childrenIndex) => {
            if (
              (this.articleIdx == 0 && childrenItem.page == "/dynamic/info") ||
              (this.articleIdx == 1 && childrenItem.page == "/dynamic/notice")
            ) {
              e = childrenItem;
              firstMenu = item.name;
              secondMenu = childrenItem.name;
            }
          });
        }
      });
      const nowLocation = "首页" + " > " + firstMenu + " > " + secondMenu;
      this.$store.dispatch("setNowLocation", nowLocation);
      this.$store.dispatch("setSecondMenu", secondMenu);
      util.openPage({
        url: e.page,
        data: { id: e.id, parent_id: e.parent_id, htmlData },
      });
    },
    goMore() {
      // 0 跳新闻资源 1景区公告
      let e = "";
      let firstMenu = "";
      let secondMenu = "";
      this.$store.getters.menuList.forEach((item, index) => {
        if (item.children.length > 0) {
          item.children.forEach((childrenItem, childrenIndex) => {
            if (
              (this.articleIdx == 0 && childrenItem.page == "/dynamic/info") ||
              (this.articleIdx == 1 && childrenItem.page == "/dynamic/notice")
            ) {
              e = childrenItem;
              firstMenu = item.name;
              secondMenu = childrenItem.name;
            }
          });
        }
      });
      const nowLocation = "首页" + " > " + firstMenu + " > " + secondMenu;
      this.$store.dispatch("setNowLocation", nowLocation);
      this.$store.dispatch("setSecondMenu", secondMenu);
      util.openPage({
        url: e.page,
        data: { id: e.id, parent_id: e.parent_id },
      });
    },
    gotoModule(val, data) {
      // dynamic/activity?id=5&parent_id=4
      switch (val) {
        case "huodong":
          this.$router.push({
            path: "/dynamic/activity",
            query: { id: "5", parent_id: "4" },
          });
          break;
        case "biaozhi":
          this.$router.push({
            path: "surveys/scenic",
            query: { id: 5, parent_id: 2 },
          });
          break;
        case "more":
          this.$router.push({
            path: "dynamic/notice",
            query: {
              id: 6,
              parent_id: 4,
            },
            params: { data },
          });
          break;
        default:
          break;
      }
    },
    goPage(page) {
      let e = "";
      let firstMenu = "";
      let secondMenu = "";
      this.$store.getters.menuList.forEach((item, index) => {
        if (item.children.length > 0) {
          item.children.forEach((childrenItem, childrenIndex) => {
            if (childrenItem.page == page) {
              e = childrenItem;
              firstMenu = item.name;
              secondMenu = childrenItem.name;
            }
          });
        }
      });
      const nowLocation = "首页" + " > " + firstMenu + " > " + secondMenu;
      this.$store.dispatch("setNowLocation", nowLocation);
      this.$store.dispatch("setSecondMenu", secondMenu);
      if (e.jump_type == 2) {
        window.open(e.url, "_blank");
        return;
      } else {
        util.openPage({
          url: e.page,
          data: { id: e.id, parent_id: e.parent_id },
        });
      }
    },
    goScenicDetail(content) {
      let e = "";
      let firstMenu = "";
      let secondMenu = "";
      this.$store.getters.menuList.forEach((item, index) => {
        if (item.children.length > 0) {
          item.children.forEach((childrenItem, childrenIndex) => {
            if (childrenItem.page == "/surveys/scenic") {
              e = childrenItem;
              firstMenu = item.name;
              secondMenu = childrenItem.name;
            }
          });
        }
      });
      const nowLocation = "首页" + " > " + firstMenu + " > " + secondMenu;
      this.$store.dispatch("setNowLocation", nowLocation);
      this.$store.dispatch("setSecondMenu", secondMenu);
      util.openPage({
        url: e.page,
        data: { id: e.id, parent_id: e.parent_id, content },
      });
    },
    goToInfo(item,type) {
      let nowLocation
      let query
      let secondMenu
      if(type == 'activity') {
        nowLocation = '首页 > 景区动态 > 精彩活动'
        secondMenu = '精彩活动'
        this.$store.dispatch("setSecondMenu", secondMenu);
        query = { 
            id: item.id,
            title: item.name,
            htmlData : item.content,
            img: item.domain_image
        }
      } else if (type == 'especially') {
        nowLocation = '首页 > 特色服务'
        const firstMenu = '特色服务'
        secondMenu = '游船租赁'
        this.$store.dispatch("setSecondMenu", firstMenu);
        query = { 
            id: item.id,
            title: item.name,
            htmlData : item.content,
            img: item.domain_image,
            firstMenu: firstMenu,
        }
      } else if (type == 'sign_scenic') {
        secondMenu = '标志景点'
        this.$store.dispatch("setSecondMenu", secondMenu);
        nowLocation = '首页 > 景区概况 > 标志景点'
        query =  { 
          id: item.id,
          title: item.name,
          htmlData : item.content,
          img: item.domain_image,
        }
      }
      this.$store.getters.menuList.forEach((item) => {
        if (item.children.length > 0) {
          item.children.forEach((childrenItem) => {
              if (childrenItem.name == secondMenu) {
                this.$store.dispatch("setBennrtList", item.banner_list);
                this.$store.dispatch("setSecondMenu", childrenItem.name);
              }
          });
        }
      });
      const nLoc = nowLocation + ' > ' + '详情';
        this.$store.dispatch("setNowLocation", nLoc);
        this.$router.push({
            path: "/special/detail",
            query: query,
        });
    },
    commonGetList(type) {
      const ts = this;
      return new Promise((resolve) => {
        util
          .requests("post", {
            url: "os/commonGetList",
            data: { table: type },
          })
          .then((res) => {
            if (res.code) {
              if (ts.articleIdx == 0 && type == "os_information") {
                ts.infoList = res.data.list.filter((item, index) => index < 4);
              }
              if (ts.articleIdx == 1 && type == "os_notice") {
                ts.infoList = res.data.list.filter((item, index) => index < 4);
              }
              if (type == "os_information") {
                ts.commonList = res.data.list.filter(
                  (item, index) => index < 4
                );
              }
              if (type == "os_notice") {
                ts.noticList = res.data.list.filter((item, index) => {
                  // if (item) item = this.showHtml(item);
                  return index < 4;
                });
              }
              if (type == "os_especially") {
                ts.tranServers = res.data.list.filter(
                  (item, index) => index < 5
                );
              }
              if (type == "os_activity") {
                ts.activityList = res.data.list.filter(
                  (item, index) => index < 8
                );
                ts.initSwiper();
              }
              if (type == "os_scenic") {
                ts.scenicList = res.data.list.filter(
                  (item, index) => index < 8
                );
              }
            }
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    getMenuList() {
      const ts = this;
      return new Promise((resolve) => {
        util
          .requests("post", {
            url: "os/getMenuList",
          })
          .then((res) => {
            if (res.code) {
              ts.bannerImg = res.data.list[0].bennrt_list;
            }
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    initSwiper() {
      this.infoSwiper = new Swiper(".mySwiper", {
        slidesPerView: 3,
        spaceBetween: 25,
        autoplay: true,
        centeredSlides: this.activityList.length === 1,
        loop: this.activityList.length > 3 ? true : false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
      // const ts = this;
      // this.infoSwiper = new Swiper(".info-swiper", {
      //   autoplay: false,
      //   centeredSlides: true,
      // });
      // if (this.bennrtList.length > 1) {
      //   this.bannerSwiper = new Swiper(".banner-swiper", {
      //     autoplay: true,
      //     loop: true,
      //     navigation: {
      //       nextEl: ".swiper-button-next",
      //       prevEl: ".swiper-button-prev",
      //     },
      //     on: {
      //       slideChange: function () {
      //         ts.bannerIndex = this.realIndex;
      //         ts.$forceUpdate();
      //       },
      //     },
      //   });
      // }

      // new Swiper("#certify .swiper-container", {
      //   watchSlidesProgress: true,
      //   slidesPerView: "auto",
      //   centeredSlides: true,
      //   loop: true,
      //   loopedSlides: 5,
      //   autoplay: true,
      //   navigation: {
      //     nextEl: ".swiper-button-next",
      //     prevEl: ".swiper-button-prev",
      //   },
      //   pagination: {
      //     el: ".swiper-pagination",
      //     //clickable :true,
      //   },
      //   on: {
      //     progress: function (progress) {
      //       for (let i = 0; i < this.slides.length; i++) {
      //         var slide = this.slides.eq(i);
      //         var slideProgress = this.slides[i].progress;
      //         let modify = 1;
      //         if (Math.abs(slideProgress) > 1) {
      //           modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
      //         }
      //         let translate = slideProgress * modify * 210 + "px";
      //         let scale = 1 - Math.abs(slideProgress) / 5;
      //         let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
      //         slide.transform(
      //           "translateX(" + translate + ") scale(" + scale + ")"
      //         );
      //         slide.css("zIndex", zIndex);
      //         slide.css("opacity", 1);
      //         if (Math.abs(slideProgress) > 3) {
      //           slide.css("opacity", 0);
      //         }
      //       }
      //     },
      //     setTransition: function (transition) {
      //       for (var i = 0; i < this.slides.length; i++) {
      //         var slide = this.slides.eq(i);
      //         slide.transition(transition);
      //       }
      //     },
      //   },
      // });
    },
    changeTab(idx) {
      this.articleIdx = idx;
      this.infoSwiper.slideTo(idx);
      // if ( idx == 0 ) {
      //     this.infoList = this.commonList
      // }
      // if ( idx == 1 ) {
      //     this.infoList = this.noticList
      // }
    },

    post() {
      const ts = this;
      Indicator.open(this.$t("tip.loading"));
      util
        .requests("post", {
          url: "pc/homeMenuList",
        })
        .then((res) => {
          Indicator.close();
          if (res.code) {
            ts.bannerImg = res.data[0].banner_list;
            ts.banner_info = ts.bannerImg[0];
            ts.info = res.data[0].children;
          }
        });
      util
        .requests("post", {
          url: "pc/homeData",
          data: { type: 2 },
        })
        .then((res) => {
          Indicator.close();
          if (res.code) {
            ts.activity = res.data.activity;
            ts.route = res.data.route;
            ts.news = res.data.travel;
            ts.choice_zixun = ts.news[0];
          }
        });
    },
    changeInfo(e) {
      this.choice_zixun = this.news[e];
    },
    Swipers(e) {
      const ts = this;
      if (e == 2) {
        var list = [];
        ts.activity.forEach((item, index) => {
          if (index > 0) {
            list.push(item);
          }
        });
        list[ts.activity.length - 1] = ts.activity[0];
        ts.activity = list;
      } else {
        var list = [];
        list.push(ts.activity[ts.activity.length - 1]);

        ts.activity.forEach((item, index) => {
          if (index < ts.activity.length - 1) {
            list.push(item);
          }
        });
        ts.activity = list;
      }
    },
    goto() {
      this.$router.push({ path: "/scenic", query: { type: "activity" } });
    },
    goDetail(e, f) {
      if (f == "1") {
        this.$router.push({
          path: "/detail",
          query: { type: "activity", id: e.id },
        });
      } else if (f == "2") {
        this.$router.push({
          path: "/detail",
          query: { type: "route", id: e.id },
        });
      } else if (f == "3") {
        this.$router.push({
          path: "/detail",
          query: { type: "travel", id: e.id },
        });
      }
    },
    goDetails() {
      this.$router.push({ path: "/line" });
    },
    getPoint(e) {
      let anchorElement = document.getElementById(e);
      if (anchorElement) {
        anchorElement.scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐。默认值
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./Home.scss";
.strip:hover {
  color: #FFFFFF !important;
  background-size: cover !important;
  background: url("../assets/body/service_border.png") no-repeat !important;
}
.content-box-act {
  position: absolute;
  bottom: 0;
  padding: 24px;
  width: 100%;
}
.act_title {
  color: #fff;
  font-size: 20px;
}
.act_label {
  width: 100px;
  height: 40px;
  line-height: 40px;
  margin: 10px 0 8px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  background-size: 100% 100%;
  background-image: url("../assets/body/border-label.png");
}
.act_dec {
  color: #fff;
  font-size: 16px;
}
.strip_content {
  height: 380px;
  width: 200px;
  color: #fff;
  font-size: 16px;
  overflow: hidden;
}

.introduce >>> img {
  max-width: 1000px !important;
}
.images {
  width: 100%;
  height: 830px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.banner {
  .swiper-button-prev,
  .swiper-button-next {
    position: initial !important;
    width: 40px;
    height: 40px;
    display: block;
    // top: calc(50% + 48px);
    &::after {
      display: none;
    }
  }
  .swiper-button-prev {
    background: url("../assets/banner-left.png") no-repeat;
    background-size: cover;
    left: 60px;
    opacity: 1 !important;
  }
  .swiper-button-next {
    background: url("../assets/banner-right.png") no-repeat;
    background-size: cover;
    right: 60px;
    opacity: 1 !important;
  }
}
// 精彩活动轮播图

#certify {
  position: relative;
  width: 1500px;
  margin: 0 auto;
  margin-top: 50px !important;
}

#certify .swiper-container {
  padding-bottom: 20px;
}
.m-t-10 {
  margin-top: 10px !important;
}

#certify .swiper-slide {
  width: 520px;
  height: 468px;
  background: #fff;
  box-shadow: 0 8px 30px #ddd;
}
#certify .swiper-slide img {
  width: 100%;
  display: block;
}
#certify .swiper-slide p {
  line-height: 98px;
  padding-top: 0;
  text-align: center;
  color: #636363;
  font-size: 16px;
  margin: 0;
}

#certify .swiper-pagination {
  width: 100%;
  bottom: 20px;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
  border: 3px solid #fff;
  background-color: #d5d5d5;
  width: 10px;
  height: 10px;
  opacity: 1;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
  border: 3px solid #00aadc;
  background-color: #fff;
}

#certify .swiper-button-prev {
  left: -30px;
  width: 45px;
  height: 45px;
  top: 230px;
  background: url("../assets/wm_button_icon.png") no-repeat;
  background-position: 0 0;
  background-size: 100%;
}

#certify .swiper-button-prev:hover {
  background-position: 0 -46px;
  background-size: 100%;
}

#certify .swiper-button-next {
  top: 230px;
  right: -30px;
  width: 45px;
  height: 45px;
  background: url("../assets/wm_button_icon.png") no-repeat;
  background-position: 0 -93px;
  background-size: 100%;
}

#certify .swiper-button-next:hover {
  background-position: 0 -139px;
  background-size: 100%;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  content: none !important;
}
.activity-images {
  width: 100%;
  height: 317px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.tags {
  margin: 0px 16px 0px;
  line-height: 0px;
  span {
    font-size: 10px;
    color: var(--bg-color);
    border: 1px solid var(--bg-color);
    padding: 3px 8px;
    border-radius: 15px;
  }
}
.title {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 25px 16px 15px;
}
.desc {
  font-size: 14px;
  color: #333333;
  margin: 0 16px;
}
.right-box {
  width: 108px;
  position: fixed;
  bottom: 100px;
  right: 0px;
  padding: 0 10px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.return-top {
  width: 100%;
  height: 108px;
  border-top: 1px #E4E4E4 solid;
  .top-icon {
    width: 42px;
    height: 42px;
    background-size: 100% 100%;
    background-image: url("../assets/body/b2top.png");
    cursor: pointer;
  }
  // .top-icon:hover {
  //     background-image: url("../assets/active-top.png") !important;

  // }
  .top-text {
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px;
    color: #fff;
  }
}
.message-box {
  width: 100%;
  height: 108px;
  z-index: 99;
  .message-icon {
    width: 42px;
    height: 42px;
    background-size: 100% 100%;
    background-image: url("../assets/body/go2message.png");
    cursor: pointer;
  }
  // .message-icon:hover {
  //     background-image: url("../assets/active-home-message.png") !important;

  // }
  .message-text {
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px;
    color: #fff;
  }
}
.all-scenic-box {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  .main-box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    .all-scenic-title-text {
      font-size: 32px;
      color: #fff;
      font-weight: bold;
    }
    .all-scenic-detail-text {
      margin: 30px 0;
      font-size: 20px;
      color: #fff;
      max-width: 700px;
    }
  }
}
.m-t-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.m-t-0 {
  margin-top: 0 !important;
}

.p-t-50 {
  padding-top: 50px !important;
}
.activity-only-images {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.m-l-0 {
  margin-left: 0px !important;
}

.tc {
  text-align: center !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.zdy {
  position: absolute;
  right: 60px;

  bottom: 30px;
  z-index: 99;
}

.default-item {
  margin: 0 5px;
  position: relative;
  bottom: 7.5px;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-image: url("../assets/default-banner-icon.png");
}

.active-banner-item {
  background-image: url("../assets/active-banner-icon.png") !important;
}

.scenic-box {
  margin-top: 60px !important;
}
</style>