import Vue from 'vue'
import Vuex from 'vuex'
import { getMenuList } from '@/api/user'
import { initMenu  } from '@/utils/auth'
import getters  from "./modules/getter"
import header from '@/store/modules/header'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routers : [],
  },
  mutations: {
    SET_MENU: (state,value) => {
      state.routers = value
    }
  },
  actions: {
        // 获取动态菜单
        GetMenuArr({ commit, state }) {
          const ts = this;
          return new Promise((resolve, reject) => {
            getMenuList().then(response => {
              var rs_list=(response.data&&Object.keys(response.data.list).length>0)?response.data.list:[]
              if (response.code==1&&Object.keys(rs_list).length>0) { // 验证返回的roles是否是一个非空数组       
                for(var i in rs_list){
                  if(rs_list[i]['url']){
                    rs_list[i]['url']=rs_list[i]['url'].replace('voice/','')
                  }
                }
                let rs=initMenu.setInit(rs_list)//对返回的menu格式处理  
                commit('SET_MENU',rs)
                resolve(rs)
              } else {
                reject(response.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },

  },
  modules: {
    header
  },
  getters
})
