var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"header-tab flex ac jb"},[_c('div',{staticClass:"left flex ac jc"},[(_vm.config.system_info)?_c('div',{staticClass:"logo-img",style:({
        background: ("url(" + (_vm.config.system_info.logo.value[0]) + ")"),
      })}):_vm._e()]),_c('div',{staticClass:"main flex ac"},_vm._l((_vm.menuList),function(item,index){return _c('section',{key:index,class:['tabs flex ac jc'],on:{"click":function($event){return _vm.getRoute(item, item.page)}}},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" "),_c('div',{class:[
          'bottom-radius flex ac jc',
          _vm.activeId == item.id ? 'active' : '' ]}),(item.children.length > 0)?_c('section',{staticClass:"header-pop"},_vm._l((item.children),function(childItem,childIndex){return _c('section',{key:childIndex,class:['flex ac jc pop-name', _vm.secondMenu == childItem.name ? 'active' : ''],on:{"click":function($event){return _vm.getModel(
              childItem,
              childItem.page,
              item.name,
              childItem.name,
              item.banner_list
            )}}},[_c('div',{staticClass:"only"},[_vm._v(_vm._s(_vm.$t(childItem.name)))])])}),0):_vm._e()])}),0),_c('div',{staticClass:"right flex ac"})])}
var staticRenderFns = []

export { render, staticRenderFns }