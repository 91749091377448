<template>
  <div class="footer-bottom">
    <div class="container flex">
      <div class="flex dc jc conect_">
        <div 
        v-if="config.system_info"
        class="footer_logo"
        :style="{
          background: `url(${config.system_info.logo.value[0]}) no-repeat`,
        }"
        >
        </div>
        <div class="flex fw" style="margin-top: 43px" v-if="config.system_info">
          <div class="flex" style="margin-bottom: 12px">
            <div class="font_16 f-1">
              <!-- 服务地址 -->
              {{$t(config.system_info.service_url.description)}}：
            </div>
            <div class="font_16 f-3 only info-line">
              {{$t(config.system_info.service_url.value)}}
            </div>
            <!-- 服务热线 -->
            <div class="font_16 f-1">
              {{$t(config.system_info.service_tel.description)}}：
            </div>
            <div class="font_16 f-3">
              {{$t(config.system_info.service_tel.value)}}
            </div>
          </div>
          <div class="flex">
            <!-- 服务时间 -->
            <div class="font_16 f-1">
              {{$t(config.system_info.service_time.description)}}：
            </div>
            <div class="font_16 f-3 only info-line">
              {{$t(config.system_info.service_time.value)}}
            </div>
            <div class="font_16 f-1">
              {{$t(config.system_info.postal_code.description)}}：
            </div>
            <div class="font_16 f-3">
              {{ $t(config.system_info.postal_code.value)}}
            </div>
          </div>
        </div>
      </div>
      <div class="conect_us flex">
        <div
          v-for="item in menuList"
          :key="item.id"
          class="flex dc as"
          style="gap: 12px"
        >
          <div class="font_20" v-if="item.id !== 1">{{ $t(item.name) }}</div>
          <div
            class="font_16"
            v-for="i in item.children"
            :key="i.id"
            @click="goPage(i.page,item)"
            style="cursor: pointer;"
          >
            {{ $t(i.name) }}
          </div>
        </div>
      </div>
      <div class="qr_code flex" v-if="config.system_info">
        <div class="qr_box">
          <div
            class="_code"
            :style="{
              background: `url(${config.system_info.qr_code_url1.value[0]})`,
            }"
          ></div>
          <div class="only" style="width: 100px;text-align: center;">
            {{ $t(config.system_info.qr_code_name1.value) }}
          </div>
        </div>
        <div class="qr_box">
          <div
            class="_code"
            :style="{
              background: `url(${config.system_info.qr_code_url2.value[0]})`,
            }"
          ></div>
          <div class="only" style="width: 100px;text-align: center;">
            {{ $t(config.system_info.qr_code_name2.value) }}
          </div>
        </div>
      </div>
    </div>
    <div class="copy-right flex jc ac" v-if="config.system_info" @click="open(config.system_info.icp_url.value)">{{ config.system_info.icp_permit.value }}</div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
var util = require("@/utils/util");
export default {
  name: "Bottom",
  data() {
    return {
      dialogVisible: false,
    };
  },
  mounted() {
    console.log(this.config.system_info);
  },
  computed: {
    ...mapGetters(["menuList", "config", "logo"]),
  },
  methods: {
    Click(val) {
      console.log(val);
        if (val.jump_type == "1") {
          // 官网内页面路径
          this.$router.push({
            path: val.page,
            query:{name:val.name}
          });
        }
        if (val.jump_type == "2") {
          // 外链
          window.open(val.url, "_blank");
        }
    },
    open(value) {
      console.log(value);
      window.open(value, "_blank");
    },
    goPage(page,value) {
      let e = "";
      let firstMenu = "";
      let secondMenu = "";
      this.$store.getters.menuList.forEach((item, index) => {
        if (item.children.length > 0) {
          item.children.forEach((childrenItem, childrenIndex) => {
            if (childrenItem.page == page) {
              e = childrenItem;
              firstMenu = item.name;
              secondMenu = childrenItem.name;
            }
          });
        }
      });
      const bennrt_list = value.banner_list
      let nowLocation
      
      if (value.name == '旅游服务') { // 旅游服务
        nowLocation = "首页" + " > " + firstMenu;
      } else {
        nowLocation = "首页" + " > " + firstMenu + " > " + secondMenu;
      }
      this.$store.dispatch("setNowLocation", nowLocation);
      this.$store.dispatch("setSecondMenu", secondMenu);
      this.$store.dispatch("setBennrtList", bennrt_list);
      util.openPage({
        url: e.page,
        data: { id: e.id, parent_id: e.parent_id },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.footer-bottom {
  background: url("../assets/footer/footer_bg.png") no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding: 56px 0 0 0;
  .container {
    width: 1440px;
    // height: 239px;
    margin: 0 auto;
    overflow: hidden;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    .footer_logo {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 102px;
      width: 301px;
    }
    .conect_us {
      color: #fff;
      gap: 30px;
    }
    .qr_code {
      gap: 20px;
      .qr_box {
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      ._code {
        background: #fff;
        height: 100px;
        width: 100px;
        border-radius: 4px;
        margin-bottom: 10px;
        background-size: cover !important;
      }
    }
  }
}
// .main-auto {
//   display: flex;
// }
.f-1 {flex: 1}
.f-3 {flex: 3}
.info-line {
  margin-right: 16px;
  max-width: 240px;
  min-width: 240px;
}

.footer_sider {
  width: 240px;
  height: 100%;
}
.div-tab {
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}
.div-tab:hover {
  color: var(--bg-color) !important;
}
.logo {
  height: 90px;
  margin-right: 30px;
}
.m-r-60 {
  margin-right: 60px;
}
.qrcode {
  img {
    width: 100px;
    height: 100px;
  }
  .item {
    margin-right: 30px;
    width: 100px;
    div {
      text-align: center;
    }
  }
  margin-bottom: 50px;
}
.copy-right {
  cursor: pointer;
  width: 1440px;
  padding: 10px 0 10px 0;
  margin: 0 auto;
  // border-top: 2px solid rgba(255, 255, 255, 0.3);
}
.label {
  color: #fff;
  font-size: 16px;
}
.text {
  color: #333;
  font-size: 20px;
  font-weight: bold;
  max-width: 500px;
}
.conect_ {
  color: #fff;
  width: 400px;
}

// --------------
.font_20 {
  font-size: 20px;
  white-space: nowrap;
}
.font_16 {
  font-size: 16px;
  white-space: nowrap;
}
</style>